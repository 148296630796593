<template>
  <div class="container">
      <div class="top">
           <Form
            :inline="true"
            class="form"
           >
                <FormItem class="item">
                    <span slot="label">操作人:</span>
                    <Input
                        :maxlength="20"
                        v-model.trim="searchForm.name"
                        placeholder="请输入操作人"
                        style="width: 150px"
                    ></Input>
                </FormItem>
                <FormItem class="item">
                    <span slot="label">修改时间:</span>
                    <DatePicker v-model="searchTime" @on-change="changeTime" type="daterange" confirm placement="bottom-end" placeholder="请选择修改时间" style="width: 200px"></DatePicker>
                </FormItem>
                <!-- <FormItem class="item">
                    <span slot="label">修改项:</span>
                    <Select
                        v-model="searchForm.options"
                        placeholder="请选择修改项"       
                    >
                        <Option
                        :value="item.orgCode"
                        v-for="(item, index) in Options"
                        :key="index"
                        >{{ item.orgName }}</Option>
                    </Select>
                </FormItem> -->
                <Button
                    type="primary"
                    icon="ios-search-outline"
                    @click="getList"
                    style="margin-right: 10px;margin-bottom:10px"
                    >查询</Button
                    >
                <Button type="success" @click="reset" icon="ios-refresh" style="margin-bottom:10px">重置</Button>
           </Form>
      </div>
      <div class="table">
          <Table  :columns="talbeColumns" :data="tableData"  height="680" border stripe></Table>
         <Page
                style="float: right; margin: 10px"
                @on-change="morePageFn"
                :current="page"
                @on-page-size-change="PageSizeChange"
                :page-size="pageSize"
                :total="total"
                :page-size-opts="[20, 50, 100, 200]"
                size="small"
                show-total
                show-elevator
                show-sizer
              />
      </div>
      <LiefengModal
        :value="selectModal"
        title="修改详情"
        @input="selectStatus"
        :fullscreen="false"
        width="800"
        height="600px"
      >
        <template v-slot:contentarea>
                <Table height="540" :loading="editLoading" :columns="editColumns" :data="eitdTableData"  border stripe></Table>
        </template>
        <template v-slot:toolsbar>
          <Button style="margin: 0 5px 0 13px" type="primary" @click="selectCancel">确定</Button>
        </template>
      </LiefengModal>
  </div>
</template>

<script>
import LiefengModal from "@/components/LiefengModal";
export default {
    props:{
            isShow:{
                type:Number,
                default:()=>{
                    return 0
                }
            }
        },
    components:{LiefengModal},
    data(){
        return{
            talbeColumns:[
                {
                    title: "操作人",
                    key: "staffName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "修改时间",
                    // key: "operatrionTime",
                    minWidth: 200,
                    align: "center",
                    render:(h,params)=>{
                        return h('div',{},params.row.operatrionTime ? this.$core.formatDate(
                            new Date(params.row.operatrionTime),
                            "yyyy-MM-dd hh:mm:ss"
                        ):'')
                    }
                },
                 {
                    title: "操作",
                    slot: "action",
                    align: "center",
                    minWidth: 300,
                    render: (h, params) => {
                        return h("div", [
                        h(
                            "Button",
                            {
                            props: {
                                type: "info",
                                size: "small"
                            },
                            on: {
                                click: () => {
                                        this.selectModal = true
                                        this.getDetail(params.row.operationId)
                                    }
                                }
                            },
                            "查看详情"
                        ),
                        ]);
                    }
                    }
            ],
            tableData:[{title:'1'}],
            loading:false,
            page:1,
            total:0,
            pageSize:20,
            searchForm:{
                name:'',
                startTime:'',
                endTime:'',
                options:''
            },
            searchTime:'',
            Options:[],
            selectModal:false,
            editColumns:[
                {
                    title: "修改项",
                    key: "fieldName",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "修改前",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
                {
                    title: "修改后",
                    key: "dataBefore",
                    minWidth: 200,
                    align: "center"
                },
            ],
            eitdTableData:[],
            editLoading:false
        }
    },
    created(){
    },
    methods:{
        selectStatus(status){
            this.selectModal = status
        },
        selectCancel(){
            this.selectModal = false
        },
        // 选择了时间的回调事件
        changeTime(val){
            if(val){
                this.searchForm.startTime = val[0]
                this.searchForm.endTime = val[1]
            }
        },

        // 重置按钮
        reset(){
             this.searchTime = []
            this.searchForm ={
                name:'',
                startTime:'',
                endTime:''
            }
            this.getList()
        },
         morePageFn(val) {
            this.page = val
            this.getList()
        },
        PageSizeChange(val){
            this.pageSize = val
            this.page = 1
            this.getList()
        },
        // 获取分页接口
        getList(){
            this.loading = true
            this.$get('/syaa/api/syuser/pc/residentArchive/selectOperationLogPage',{
                page:this.page,
                pageSize:this.pageSize,
                staffName:this.searchForm.name,
                operatrionTimeStart:this.searchForm.startTime,
                operatrionTimeEnd:this.searchForm.endTime,
                operationType: 2 //查看修改记录
            }).then(res=>{
                this.loading = false
                if(res.code == 200){
                    this.tableData = res.dataList
                    this.total = res.maxCount

                }else{
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        },
        // 获取详情接口
        getDetail(data){
            this.editLoading = true
            this.$get('/syaa/api/syuser/pc/residentArchive/selectUpdateDetail',{
                operationId:data,
                userId:parent.vue.loginInfo.userinfo.custGlobalId
            }).then(res=>{
                this.editLoading = false
                if(res.code == 200){
                    this.eitdTableData = res.dataList
                }else{
                    this.tableData = []
                    this.$Message.error({
                        content:res.desc,
                        background:true
                    })
                    return
                }
            })
        }
    },
    watch:{
        isShow:{
            handler(val){
                if(val){
                    this.page = 1
                    this.pageSize = 20
                    this.getList()
                }
            }
        }
    }
}
</script>

<style lang="less" scoped>
    .container{
        .top{
            height: 40px;
            width: 100%;
            line-height: 40px;
            display: flex;
            .form{
                margin-left: auto;
            }
        }
    }
    /deep/.ivu-form-item-content{
        display: flex;
    }
    /deep/.ivu-table-tip{
        overflow-x: hidden !important;
    }
     /deep/ #modal_contentarea{
        overflow: visible !important;
    }
</style>